import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { withFormik } from 'formik';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import {
  EPP_LICENSE_HOLDER,
  REPORTING_FOR_PROVIDER,
} from '../ProviderInformation/Organisation';

const formikWrapper = withFormik({
  validateOnMount: false,
  validateOnBlur: false,
  validateOnChange: false,
  mapPropsToValues: (props) => ({
    ...props.initialValues,
  }),
  handleSubmit: (values, formikBag) => {
    values.matomo.trackEvent({
      category: 'Check answers',
      action: 'Completed',
    });
    delete values.matomo;
    formikBag.props.updateValues(values, 'uploaded');
  },
});

const QuestionRow = ({
  name,
  changeName,
  children,
  onChange,
  section,
  productIndex,
}) => {
  const reviewKey = `${section}__${name}`.toLowerCase().replace(/\s/, '-');
  return (
    <div className="govuk-summary-list__row">
      <dt className="govuk-summary-list__key">{name}</dt>
      <dd className="govuk-summary-list__value" data-review-key={reviewKey}>
        {children}
      </dd>
      <dd className="govuk-summary-list__actions">
        {onChange ? (
          <a
            className="govuk-link"
            href=""
            onClick={(e) => onChange(e, section, productIndex)}
            data-test={reviewKey}
          >
            Change<span className="govuk-visually-hidden"> {changeName}</span>
          </a>
        ) : (
          ''
        )}
      </dd>
    </div>
  );
};

const hasOne = (matches, set) => {
  let hasMatch = false;
  for (const match of matches) {
    hasMatch = hasMatch || set.has(match);
  }
  return hasMatch;
};

const Section = ({ matches, sections, children }) => {
  return hasOne(matches, sections) ? children : '';
};

const Date = ({ value = {} }) => {
  const { day, month, year } = value;
  return day || month || year ? `${day}/${month}/${year}` : '';
};

const Address = ({ value = {} }) => {
  const { addressLine1, addressLine2, city, county, postcode, country } = value;
  return addressLine1 || addressLine2 || city || county || postcode ? (
    <>
      {addressLine1}
      <br />
      {addressLine2}
      <br />
      {city}
      <br />
      {county}
      <br />
      {postcode}
      <br />
      {country}
    </>
  ) : (
    ''
  );
};

const ReviewAnswers = ({ handleSubmit, values, editSection, updateValues }) => {
  const { trackPageView, trackEvent } = useMatomo();
  values.matomo = { trackEvent };
  const history = useHistory();
  useEffect(() => {
    trackPageView();
    trackEvent({
      category: 'Check answers',
      action: 'Started',
    });

    window.scrollTo(0, 0);
    document.title =
      'Review your answers - Report Suspicious Chemical Activity';
  }, []);

  const onChange = (e, section) => {
    e.preventDefault();
    editSection(section);
  };

  const onProductChange = (e, section, i) => {
    e.preventDefault();
    values.productIndex = i;
    values.editMode = true;
    updateValues(values, section);
  };

  return (
    <div>
      <a className="govuk-back-link" href="#" onClick={() => history.goBack()}>
        Back
      </a>
      <main
        className="govuk-main-wrapper govuk-main-wrapper--auto-spacing"
        id="content"
        role="main"
        tabIndex={0}
      >
        <div className="govuk-grid-row">
          <div className="govuk-grid-column-two-thirds">
            <h1 className="govuk-heading-l">
              Check your answers before sending your report
            </h1>
            <p className="govuk-body">
              By submitting this report you are confirming that, to the best of
              your knowledge, the information you have provided is correct.
            </p>
            {/* About You */}
            <Section
              matches={['provider-information']}
              sections={values.sections}
            >
              <h2 className="govuk-heading-m">Your details</h2>
              <dl className="govuk-summary-list govuk-!-margin-bottom-9">
                <QuestionRow
                  name="Name"
                  changeName={'Provider name'}
                  section="provider-information"
                  onChange={onChange}
                >
                  {values.provider.person_fullname}
                </QuestionRow>
                <QuestionRow
                  name="Email"
                  changeName={'Provider email'}
                  section="provider-information"
                  onChange={onChange}
                >
                  {values.provider.email}
                </QuestionRow>
                <QuestionRow
                  name="Phone"
                  changeName={'Provider telephone number'}
                  section="provider-information"
                  onChange={onChange}
                >
                  {values.provider.telephone}
                </QuestionRow>
                <QuestionRow
                  name="Contact method"
                  changeName={'Provider contact method'}
                  section="provider-information"
                  onChange={onChange}
                >
                  {values.provider.contact_method}
                </QuestionRow>
              </dl>
            </Section>
            {/* About the activity */}
            <h2 className="govuk-heading-m">About the activity</h2>
            <dl className="govuk-summary-list govuk-!-margin-bottom-9">
              <QuestionRow name="Type">
                {values.about_incident.report_type}
              </QuestionRow>
              {values.about_incident.report_type === 'A theft' && (
                <QuestionRow
                  name="Crime reference number"
                  changeName={'Crime Reference Number'}
                  section="crime-reference"
                  onChange={onChange}
                >
                  {values.about_person.crime_details.crime_reference}
                </QuestionRow>
              )}
              <QuestionRow
                name="Date"
                changeName={'Transaction date'}
                section="transaction-date"
                onChange={onChange}
              >
                <Date value={values.transaction.date} />
              </QuestionRow>
              <QuestionRow
                name="Time"
                changeName={'Transaction time'}
                section="transaction-date"
                onChange={onChange}
              >
                {values.transaction.time}
              </QuestionRow>
              {(values.about_incident.report_type === 'A completed purchase' ||
                values.about_incident.report_type === 'An attempted purchase' ||
                values.about_incident.report_type === 'A theft' ||
                values.about_incident.report_type === 'A disappearance') && (
                <QuestionRow
                  name="Location"
                  changeName={'Transaction type'}
                  section="transaction-information"
                  onChange={onChange}
                >
                  {values.transaction.type}
                </QuestionRow>
              )}
              {(values.about_incident.report_type === 'A completed purchase' ||
                values.about_incident.report_type === 'An attempted purchase' ||
                values.about_incident.report_type === 'A theft' ||
                values.about_incident.report_type === 'A disappearance') &&
                values.transaction.type === 'Other' && (
                  <QuestionRow
                    name="Other Location"
                    changeName={'Transaction type'}
                    section="transaction-information"
                    onChange={onChange}
                  >
                    {values.transaction.other_transaction}
                  </QuestionRow>
                )}
              <QuestionRow
                name="Further information"
                changeName={'furtherInformation'}
                section="transaction-information"
                onChange={onChange}
              >
                {values.transaction.further_information}
              </QuestionRow>
              <QuestionRow
                name="Reason"
                changeName={'Reason for suspicion'}
                section="suspicion"
                onChange={onChange}
              >
                {values.about_incident.reason_suspicious}
              </QuestionRow>
            </dl>
            {/* Organisation */}
            <Section matches={['organisation']} sections={values.sections}>
              <h2 className="govuk-heading-m">Organisation details</h2>
              <dl className="govuk-summary-list govuk-!-margin-bottom-9">
                <QuestionRow
                  name="Organisation Affiliation"
                  changeName={'Report for provider'}
                  section="organisation"
                  onChange={onChange}
                >
                  {values.provider.reporting_for_org}
                </QuestionRow>
                {values.provider.reporting_for_org ===
                  REPORTING_FOR_PROVIDER && (
                  <QuestionRow
                    name="Organisation Name"
                    changeName={'Report for provider'}
                    section="business-name"
                    onChange={onChange}
                  >
                    {values.provider.organisation_name}
                  </QuestionRow>
                )}
                {values.provider.reporting_for_org === EPP_LICENSE_HOLDER && (
                  <QuestionRow
                    name="EPP License Number"
                    changeName={'Report for Provider'}
                    section="epp-license"
                    onChange={onChange}
                  >
                    {values.provider.epp_license_number}
                  </QuestionRow>
                )}
              </dl>
            </Section>

            {/* Details about the suspicious persons */}
            {(values.about_incident.report_type === 'A completed purchase' ||
              values.about_incident.report_type ===
                'An attempted purchase') && (
              <Section
                matches={['buyer-information']}
                sections={values.sections}
              >
                <h2 className="govuk-heading-m">About the person</h2>
                <dl className="govuk-summary-list govuk-!-margin-bottom-9">
                  <QuestionRow
                    name="Name"
                    changeName={'Buyer name'}
                    section="buyer-information"
                    onChange={onChange}
                  >
                    {values.about_person.buyer.fullname}
                  </QuestionRow>
                  <QuestionRow
                    name="Email"
                    changeName={'Buyer email'}
                    section="buyer-information"
                    onChange={onChange}
                  >
                    {values.about_person.buyer.email}
                  </QuestionRow>
                  <QuestionRow
                    name="Telephone"
                    changeName={'Buyer telephone'}
                    section="buyer-information"
                    onChange={onChange}
                  >
                    {values.about_person.buyer.telephone}
                  </QuestionRow>
                  <QuestionRow
                    name="Date of birth"
                    changeName={'Buyer date of birth'}
                    section="buyer-information"
                    onChange={onChange}
                  >
                    <Date value={values.about_person.buyer.dob} />
                  </QuestionRow>
                  <QuestionRow
                    name="Billing address"
                    changeName={'Buyer billing'}
                    section="buyer-information"
                    onChange={onChange}
                  >
                    {values.about_person.buyer.billing}
                  </QuestionRow>
                  <QuestionRow
                    name="Delivery address"
                    changeName={'Buyer delivery'}
                    section="buyer-information"
                    onChange={onChange}
                  >
                    {values.about_person.buyer.delivery}
                  </QuestionRow>
                  <QuestionRow
                    name="Description"
                    changeName={'Buyer description'}
                    section="buyer-information"
                    onChange={onChange}
                  >
                    {values.about_person.buyer.description}
                  </QuestionRow>
                </dl>
              </Section>
            )}
            {/* Payment method */}
            <Section matches={['payment-method']} sections={values.sections}>
              <h2 className="govuk-heading-m">Payment method</h2>
              <dl className="govuk-summary-list govuk-!-margin-bottom-9">
                <QuestionRow
                  name="Payment type"
                  changeName={'transaction.payment_method'}
                  section="payment-method"
                  onChange={onChange}
                >
                  {values.transaction.payment_method}
                </QuestionRow>
                {values.transaction.payment_method === 'Other payment type' && (
                  <QuestionRow
                    name="Other payment type"
                    changeName={'Other payment description'}
                    section="payment-method"
                    onChange={onChange}
                  >
                    {values.transaction.other_payment}
                  </QuestionRow>
                )}
                <QuestionRow
                  name="Further information"
                  changeName={'Payment description'}
                  section="payment-method"
                  onChange={onChange}
                >
                  {values.transaction.payment_description}
                </QuestionRow>
              </dl>
            </Section>
            {/* Product Details */}

            {values.products.map((p, i) => (
              <Section
                key={`review-product-summary-${i}`}
                matches={[`product-information.${i}`]}
                sections={values.sections}
              >
                <h2 className="govuk-heading-m">Product details</h2>
                <dl className="govuk-summary-list govuk-!-margin-bottom-9">
                  <QuestionRow
                    name="Product name"
                    changeName={`Product ${i + 1} name`}
                    section="product-information"
                    onChange={onProductChange}
                    productIndex={i}
                  >
                    {p.product_name}
                  </QuestionRow>
                  <QuestionRow
                    name="Description"
                    changeName={`Product ${i + 1} description`}
                    section="product-information"
                    onChange={onProductChange}
                    productIndex={i}
                  >
                    {p.product_description}
                  </QuestionRow>
                  <QuestionRow
                    name="Product quantity"
                    changeName={`Product ${i + 1} Quantity`}
                    section="product-information"
                    onChange={onProductChange}
                    productIndex={i}
                  >
                    {p.product_quantity}
                  </QuestionRow>
                  <Section
                    matches={[`weight-or-item.${i}`]}
                    sections={values.sections}
                  >
                    <QuestionRow name="Sold by">{p.weight_or_item}</QuestionRow>
                  </Section>
                  <Section
                    matches={[`unit-details.${i}`]}
                    sections={values.sections}
                  >
                    <QuestionRow
                      name="Unit"
                      changeName={`Product ${i + 1} units`}
                      section="unit-details"
                      onChange={onProductChange}
                      productIndex={i}
                    >
                      {p.product_units}
                    </QuestionRow>
                    {p.product_units_other ? (
                      <QuestionRow
                        name="Unit - Other"
                        changeName={`Product ${i + 1} units - other`}
                        section="unit-details"
                        onChange={onProductChange}
                        productIndex={i}
                      >
                        {p.product_units_other}
                      </QuestionRow>
                    ) : (
                      ''
                    )}
                  </Section>
                  <Section
                    matches={[`weight-details.${i}`]}
                    sections={values.sections}
                  >
                    <QuestionRow
                      name="Product volume"
                      changeName={`Product ${i + 1} volume`}
                      section="weight-details"
                      onChange={onProductChange}
                      productIndex={i}
                    >
                      {p.volume}
                    </QuestionRow>
                  </Section>
                  <Section
                    matches={[`item-details.${i}`]}
                    sections={values.sections}
                  >
                    <QuestionRow
                      name="Individual items"
                      changeName={`Product ${i + 1} individual items sold`}
                      section="item-details"
                      onChange={onProductChange}
                      productIndex={i}
                    >
                      {p.quantity_sold}
                    </QuestionRow>
                    <QuestionRow
                      name="Volume"
                      changeName={`Product ${i + 1} item volume`}
                      section="item-details"
                      onChange={onProductChange}
                      productIndex={i}
                    >
                      {p.volume}
                    </QuestionRow>
                  </Section>
                  <Section
                    matches={[`multiple-item-details.${i}`]}
                    sections={values.sections}
                  >
                    <QuestionRow
                      name="Number of packs"
                      changeName={`Product ${i + 1} number of packs`}
                      section="multiple-item-details"
                      onChange={onProductChange}
                      productIndex={i}
                    >
                      {p.quantity}
                    </QuestionRow>
                    <QuestionRow
                      name="Number in pack"
                      changeName={`Product ${i + 1} number in pack`}
                      section="multiple-item-details"
                      onChange={onProductChange}
                      productIndex={i}
                    >
                      {p.quantity_in_pack}
                    </QuestionRow>
                    <QuestionRow
                      name="Volume"
                      changeName={`Product ${i + 1} volume in pack`}
                      section="multiple-item-details"
                      onChange={onProductChange}
                      productIndex={i}
                    >
                      {p.volume}
                    </QuestionRow>
                  </Section>
                  <Section matches={[`cost.${i}`]} sections={values.sections}>
                    <QuestionRow
                      name="Cost"
                      changeName={`Product ${i + 1} cost`}
                      section="cost"
                      onChange={onProductChange}
                      productIndex={i}
                    >
                      {p.product_unit_price_paid}
                    </QuestionRow>
                    <QuestionRow
                      name="Currency"
                      changeName={`Product ${i + 1} currency`}
                      section="cost"
                      onChange={onProductChange}
                      productIndex={i}
                    >
                      {p.currency}
                    </QuestionRow>
                  </Section>
                  <Section
                    matches={[`is-discount.${i}`]}
                    sections={values.sections}
                  >
                    <QuestionRow
                      name="Has discount"
                      changeName={`Product ${i + 1} has discount`}
                    >
                      {p.is_discount}
                    </QuestionRow>
                  </Section>
                  <Section
                    matches={[`discount-details.${i}`]}
                    sections={values.sections}
                  >
                    <QuestionRow
                      name="Discount details"
                      changeName={`Product ${i + 1} discount details`}
                      section="discount-details"
                      onChange={onProductChange}
                      productIndex={i}
                    >
                      {p.discount_details}
                    </QuestionRow>
                  </Section>
                  <Section
                    matches={[`discount-applied.${i}`]}
                    sections={values.sections}
                  >
                    <QuestionRow
                      name="Discount applied"
                      changeName={`Product ${i + 1} discount applied`}
                      section="discount-applied"
                      onChange={onProductChange}
                      productIndex={i}
                    >
                      {p.product_unit_discount_amount}
                    </QuestionRow>
                  </Section>
                </dl>
              </Section>
            ))}

            <Section matches={['more-info']} sections={values.sections}>
              <h2 className="govuk-heading-m">More information</h2>
              <dl className="govuk-summary-list govuk-!-margin-bottom-9">
                <QuestionRow
                  name="Description"
                  changeName={'Other information'}
                  section="more-info"
                  onChange={onChange}
                >
                  {values.transaction.further_info}
                </QuestionRow>
              </dl>
            </Section>

            {values.additional_emails.length > 0 && (
              <Section
                matches={['additional-email']}
                sections={values.sections}
              >
                <h2
                  className="govuk-heading-m"
                  data-test="additional_email_header"
                >
                  Receiving a report copy
                </h2>
                <p data-test="additional_email_description">
                  The following users will receive a copy of the report.
                </p>
                <dl className="govuk-summary-list govuk-!-margin-bottom-9">
                  {values.additional_emails.map((email, index) => (
                    <div
                      className="govuk-summary-list__row"
                      key={`additional-emails-summary-${index}`}
                    >
                      <dd
                        className="govuk-summary-list__value"
                        data-test={`additional-email-${index}`}
                      >
                        {email}
                      </dd>
                    </div>
                  ))}
                </dl>
              </Section>
            )}
            <form onSubmit={handleSubmit}>
              <button
                type="submit"
                data-prevent-double-click="true"
                className="govuk-button"
                data-module="govuk-button"
                data-test={`button-continue`}
                aria-label="Submit your report"
              >
                Submit your report
              </button>
            </form>
          </div>
        </div>
      </main>
    </div>
  );
};

const ReviewAnswersWithFormik = formikWrapper(ReviewAnswers);

export { ReviewAnswersWithFormik as ReviewAnswers, Section, Date, Address };
