import React, { useEffect } from 'react';
import { InputBox } from '../components';
import { Form, useFormikContext, withFormik } from 'formik';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { EMAIL_ERROR_MSG } from '../validation-utils';

const AdditionalEmailSchema = Yup.object().shape({
  transaction: Yup.object().shape({
    additional_email: Yup.string().email(EMAIL_ERROR_MSG),
  }),
});

const formikWrapper = withFormik({
  mapPropsToValues: (props) => ({
    ...props.initialValues,
  }),
  validationSchema: AdditionalEmailSchema,
  validateOnMount: false,
  validateOnBlur: false,
  validateOnChange: true,
  handleSubmit: (values, formikBag) => {
    values.matomo.trackEvent({
      category: 'Adding additional emails',
      action: 'Completed',
    });
    delete values.matomo;
    values.sections.add('additional-email');
    formikBag.props.updateValues(values, 'review-answers');
  },
});

const AdditionalEmail = ({ values, setFieldValue }) => {
  const formik = useFormikContext();
  const { trackPageView, trackEvent } = useMatomo();
  const history = useHistory();
  values.matomo = { trackEvent };
  values.additional_emails = !values.additional_emails
    ? []
    : values.additional_emails;
  useEffect(() => {
    trackPageView();
    trackEvent({
      category: 'Adding additional emails',
      action: 'Started',
    });
    window.scrollTo(0, 0);
    document.title =
      'Receive a copy of your report - Report Suspicious Chemical Activity';
  }, []);

  const onAddEmail = (e) => {
    e.preventDefault();
    if (values.transaction.additional_email) {
      values.additional_emails.push(values.transaction.additional_email);
    }
    setFieldValue(values, 'additional-emails');
    values.transaction.additional_email = '';
  };
  const onRemoveEmail = (e, i) => {
    e.preventDefault();
    values.additional_emails.splice(i, 1);
    setFieldValue(values, 'additional-emails');
  };
  return (
    <div>
      <a
        className="govuk-back-link"
        href="#"
        onClick={() => {
          if (!values.editMode) {
            setFieldValue(
              `sections`,
              values.sections.delete(`additional-emails`)
            );
          }
          history.goBack();
        }}
      >
        Back
      </a>
      <main
        className="govuk-main-wrapper govuk-main-wrapper--auto-spacing"
        id="content"
        role="main"
        tabIndex={0}
      >
        <div className="govuk-grid-row">
          <div className="govuk-grid-column-two-thirds">
            <Form autoComplete="on" onSubmit={formik.handleSubmit}>
              <div className="govuk-grid-column">
                <h1 className="govuk-heading-l">
                  Receive a copy of your report
                </h1>
                <InputBox
                  textDescription="If you would like other people or teams to receive a copy of your report, you can add their emails below"
                  name="transaction.additional_email"
                />
                <button
                  data-prevent-double-click="true"
                  className="govuk-button govuk-button--secondary"
                  data-module="govuk-button"
                  data-test={`button-add-email`}
                  aria-label="add-additional-emails"
                  onClick={onAddEmail}
                  disabled={
                    !formik.isValid || !values.transaction.additional_email
                  }
                >
                  Add email
                </button>
              </div>
              {values.additional_emails.length > 0 && (
                <dl className="govuk-summary-list govuk-!-margin-bottom-9">
                  {values.additional_emails.map((email, index) => (
                    <div
                      className="govuk-summary-list__row"
                      key={`additonal-emails-summary-${index}`}
                    >
                      <dt className="govuk-summary-list__key">{`${
                        index + 1
                      }`}</dt>
                      <dd
                        className="govuk-summary-list__value"
                        data-test={`additional-email-${index}`}
                      >
                        {email}
                      </dd>
                      <dd className="govuk-summary-list__actions">
                        <a
                          className="govuk-link"
                          href=""
                          onClick={(e) => onRemoveEmail(e, index)}
                          data-test="product-remove"
                        >
                          Remove
                        </a>
                      </dd>
                    </div>
                  ))}
                </dl>
              )}
              <button
                type="submit"
                data-prevent-double-click="true"
                className="govuk-button"
                data-module="govuk-button"
                data-test={`button-continue`}
                aria-label="continue"
              >
                Continue
              </button>
            </Form>
          </div>
        </div>
      </main>
    </div>
  );
};

const AdditionalEmailWithFormik = formikWrapper(AdditionalEmail);

export { AdditionalEmailWithFormik as AdditionalEmail };
